<template>
  <div class="about">
    <div class="about-banner">
      <b-container>
        <div class="about-description">
          <div>科学家、IT极客、行业分析师的共同体，创造机器理解金融市场。</div>
          <b-button class="">加入宽途</b-button>
        </div>
      </b-container>
      <div class="about-overlay"></div>
    </div>
    <b-container class="about-container">
      <h3>成立背景</h3>
      <p class="about-p">量化交易是投资顺应时代的进化 ，是科技赋予投资的焕然新生。 传统数字货币投资模式势必在市 场的驱动下，全面迎来量化时代。宽途量化专注于数字货币量化投资策略、交易系统和量化产品的研发，致力打造国际顶级数字货币量化对冲基金</p>
    </b-container>
    <b-container class="about-container system-container">
      <h3>专业系统</h3>
      <p class="about-p">“Quanto”交易平台具有<b class="about-b">分布式部署、负载均衡、策略沙箱、 集中风控、极速交易、消息总线、行情代理、加密通讯</b>等特点，专业度与可扩展度为行业领先。
      </p>
      <img class="system-struct-img" src="@/assets/system-struct.png" alt="系统架构图">
    </b-container>
    <b-container class="about-container">
      <h3>专业人才</h3>
      <p class="about-p">核心团队具备<b class="about-b">多年华尔街实战经验</b>，公司员工超过60%具有海外背景，在中国从事量化投资业务超过6年。</p>
      <p>团队深耕数字货币市场许久，对数字货币市场的<b class="about-b">交易策略、交易规则、 市场规范、市场特点、风险</b>等均有全面的把握。</p>
    </b-container>
    <b-container class="about-container">
      <h3>信息优势</h3>
      <p class="about-p">团队获得了火币、Binance以及OKEx做市商资格，拥有得天独厚的数字货币市场<b class="about-b">交易所端、机构端与零售客户端</b>的信息优势。</p>
    </b-container>
    <b-container class="about-container">
      <h3>宽途文化</h3>
      <b-row class="about-p hide-in-mobile">
        <b-col md="12" lg="4">
          <b-card
            overlay
            :img-src="require('@/assets/about-flat.jpg')"
            img-alt="扁平"
            text-variant="white"
            title="扁平"
            body-class="culture-card-body"
            sub-title="">
          </b-card>
        </b-col>
        <b-col md="12" lg="4">
          <b-card
            overlay
            :img-src="require('@/assets/about-free.jpg')"
            img-alt="自由"
            text-variant="white"
            title="自由"
            body-class="culture-card-body"
            sub-title="">
          </b-card>
          <p class="about-p"><b>扁平的组织架构，给你最广阔的舞台和上升空间<br/>每一个声音值得被听见</b></p>
        </b-col>
        <b-col md="12" lg="4">
          <b-card
            overlay
            :img-src="require('@/assets/about-communication.jpg')"
            img-alt="沟通顺畅"
            text-variant="white"
            title="沟通顺畅"
            body-class="culture-card-body"
            sub-title="">
          </b-card>
        </b-col>
      </b-row>
      <b-row class="about-p hide-in-mobile">
        <b-col md="12" lg="4">
          <b-card
            overlay
            :img-src="require('@/assets/about-creative.jpg')"
            img-alt="创新"
            text-variant="white"
            title="创新"
            body-class="culture-card-body-2"
            sub-title="">
          </b-card>
        </b-col>
        <b-col md="12" lg="4" class="about-align-end">
          <p><b>热爱研究、保持严谨也衷于创新<br/>突破边界、探索未知、勇敢挑战最前沿科技</b></p>
          <b-card
            class="about-p"
            overlay
            :img-src="require('@/assets/about-rigor.jpg')"
            img-alt="严谨"
            text-variant="white"
            title="严谨"
            body-class="culture-card-body-2"
            sub-title="">
          </b-card>
        </b-col>
        <b-col md="12" lg="4">
          <b-card
            overlay
            :img-src="require('@/assets/about-research.jpg')"
            img-alt="自主研究"
            text-variant="white"
            title="自主研究"
            body-class="culture-card-body-2"
            sub-title="">
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <div class="hide-in-pc about-p">
      <b-card
        overlay
        :img-src="require('@/assets/about-flat.jpg')"
        img-alt="扁平"
        text-variant="white"
        title="扁平"
        body-class="culture-card-body"
        sub-title="">
      </b-card>
      <b-card
        overlay
        :img-src="require('@/assets/about-free.jpg')"
        img-alt="自由"
        text-variant="white"
        title="自由"
        body-class="culture-card-body"
        sub-title="">
      </b-card>
      <b-card
        overlay
        :img-src="require('@/assets/about-communication.jpg')"
        img-alt="沟通顺畅"
        text-variant="white"
        title="沟通顺畅"
        body-class="culture-card-body"
        sub-title="">
      </b-card>
      <b-container>
        <p class="about-p"><b>扁平的组织架构，给你最广阔的舞台和上升空间<br/>每一个声音值得被听见</b></p>
      </b-container>
      <b-card
        overlay
        :img-src="require('@/assets/about-creative.jpg')"
        img-alt="创造"
        text-variant="white"
        title="创造"
        body-class="culture-card-body-2"
        sub-title="">
      </b-card>
      <b-card
        overlay
        :img-src="require('@/assets/about-rigor.jpg')"
        img-alt="严谨"
        text-variant="white"
        title="严谨"
        body-class="culture-card-body-2"
        sub-title="">
      </b-card>
      <b-card
        overlay
        :img-src="require('@/assets/about-research.jpg')"
        img-alt="自主研究"
        text-variant="white"
        title="自主研究"
        body-class="culture-card-body-2"
        sub-title="">
      </b-card>
      <b-container class="about-p">
        <p><b>热爱研究、保持严谨也衷于创新<br/>突破边界、探索未知、勇敢挑战最前沿科技</b></p>
      </b-container>
    </div>
    <b-container class="about-container">
      <h3>招聘岗位</h3>
      <p class="about-p">后端架构师</p>
      <p class="about-p">前端架构师</p>
      <p class="about-p">量化基金经理</p>
      <p class="about-p">投资顾问</p>
      <p class="about-p">实习生</p>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'About',
}
</script>

<style lang="less" scoped>
@import '../assets/styles/variables';

.about {
  background: #fff;
  padding-bottom: 100px;

  /deep/ .card {
    overflow: hidden;

    img {
      transition: all 0.5s ease-out;
      transform: scale(1);
    }

    &:hover {
      img {
        transform: scale(1.1);
      }
    }

    @media (max-width: 768px) {
      &, .card-body, img {
        border-radius: 0;
      }
    }
  }

  .btn {
    margin-top: 40px;
    position: relative;
    z-index: 1;
    padding: 0 24px;
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    border: 1px solid #444;
    border-radius: 4px;
    box-shadow: ~'0 0.02rem 0.04rem rgb(104 89 36 / 25%)';
    background: #fff;
    color: #000;
    font-weight: bold;

    &:hover {
      box-shadow: ~'0 4px 6px rgb(104 89 36 / 50%)';
      transition: .4s;
    }
  }

  @media (max-width: 768px) {
    padding-bottom: 0;
  }

}

.about-banner {
  position: relative;
  background: url("../assets/banner-about.jpg") bottom right / auto 100% no-repeat;
  z-index: 99;
  transition: all 0.5s ease-out;

  &:hover {
    background-size: auto 105%;
  }

  &:after {
    content: "";
    display: block;
    height: 100%;
    background: url(../assets/banner-cover.png) bottom no-repeat;
    background-size: cover;
    position: absolute;
    bottom: -8px;
    left: 0;
    right: 0;
    z-index: -1;
  }
}

.about-container {
  margin-top: 40px;
}

.about-description {
  padding: 180px 0 250px;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  @media (max-width: 768px) {
    padding-top: 150px;
    padding-bottom: 50px;
  }
}

.about-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  opacity: .9;
  background: linear-gradient(-29deg, #616d86, #1f1c2c);
}

.about-p {
  margin-top: 20px;
  font-size: 16px;
}

.about-b {
  color: @red;
}

.system-struct-img {
  width: 100%;
}

.culture-card-body {
  background: rgba(0, 0, 0, .5);
  display: flex;
  align-items: flex-end;
}

.culture-card-body-2 {
  .culture-card-body;
  background: none;
}

.about-align-end {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
</style>
